import { useContext, useEffect, useMemo, useState } from 'react'
import { useRowSelect, useTable } from 'react-table'
import { ButtonWrapper } from '../../composites/dynamicForm/style'
import DeleteConfirmationModal from '../../composites/forms/deleteConfirmationModal/DeletePopUp'
import { DomnCode } from '../../constant/DomnCode'
import { LobInfoContext } from '../../services/LobOfficeCode/LobOfficeContext'
import RetirementDetailsContext from '../../services/Retirement/RetirementContext'
import XrefContext from '../../store/XrefContext'
import { COLORS } from '../../theme/Colors'
import Button from '../button/Button'
import { OptionProps } from '../selectDropdown/ISelectDropdown'
import { SvgAdd } from '../svg/SvgAddIcon'
import { SvgBin } from '../svg/SvgBin'
import { Textbox } from '../textbox/Textbox'
import { WDButtonTransparent } from '../ui/WDButton'
import { WDTextboxBorderRadius } from '../ui/WDTextbox'
import { TableProps } from './ITable'
import {
  RootContainer,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableWrapper,
  ThContentWrapper,
  ThWrapper,
} from './RetirementStyles'
import SelectDropdown from '../../composites/selectDropdown/SelectDropdown'

const RetirementTable = ({
  tableColumns,
  tableData,
  hasFooter = false,
  handleNewValue,
  setSelectedData,
  deleteSelectedRow,
  type,
  readOnly,
}: TableProps) => {
  const {
    selectedDataTotal,
    selectedDataContingentTotal,
    beneOwnerTotal,
    trustTotal,
    addiParticipantTotal,
    setSelectedAddiParticipant,
  } = useContext(RetirementDetailsContext)
  const data = useMemo(() => tableData, [tableData])
  const columns = useMemo(() => tableColumns, [tableColumns])
  const tableInstance = useTable({ columns, data }, useRowSelect)
  const [showPopup, setShowPopup] = useState(false)
  const [currentRow, setCurrentRow] = useState<any>()
  const [currentIndex, setCurrentIndex] = useState<any>()
  const xrefStore = useContext(XrefContext)
  const { lobOfcCode } = useContext(LobInfoContext)

  const [addlParticipantOptions, setAddlParticipantOptions] = useState<
    OptionProps[]
  >([
    {
      label: '',
      value: '-1',
    },
  ])

  const getXrefDatabyDomainCode = (response: any, code: string) => {
    const res = response?.data
    const xrefdData = []
    for (const prop in res) {
      if (
        res[prop].domncode == code &&
        res[prop].value !== '--Please Select--'
      ) {
        xrefdData.push(res[prop])
      }
    }
    return xrefdData
  }

  const bindAllDropDownsWithXref = () => {
    xrefStore
      .getXrefDetails({
        appid: '2',
        lob: lobOfcCode[0]?.lobid,
      })
      .then((xrefDetails) => {
        const ADDNPART = getXrefDatabyDomainCode(xrefDetails, DomnCode.ADDNPART)
        const localADDNPART: OptionProps[] = []
        ADDNPART.map((element) => {
          localADDNPART.push({
            label: element.value,
            value: element.code,
          })
        })
        setAddlParticipantOptions(localADDNPART)
      })
  }

  const [relationshipOptions] = useState([
    { label: 'Spouse', value: 'spouse' },
    { label: 'Spouse>10 Years Younger', value: 'spouse>10YearsYounger' },
    { label: 'others', value: 'others' },
  ])

  let isCalled = false
  useEffect(() => {
    if (!isCalled) {
      bindAllDropDownsWithXref()
      isCalled = true
    }
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance
  const deleteRow = (row: any) => {
    setSelectedData &&
      setSelectedData((prev: any) => {
        const newSelectedData = [...prev]
        newSelectedData.splice(row.index, 1)
        return newSelectedData
      })
  }

  const sumValue = () => {
    if (type === 'primary') {
      return selectedDataTotal && selectedDataTotal >= 100 ? true : false
    } else if (type === 'contingent') {
      return selectedDataContingentTotal && selectedDataContingentTotal >= 100
        ? true
        : false
    } else if (type === 'beneOwner') {
      return beneOwnerTotal && beneOwnerTotal >= 100 ? true : false
    } else if (type === 'addlPpt') {
      return addiParticipantTotal && addiParticipantTotal >= 100 ? true : false
    } else if (type === 'trust') {
      return trustTotal.trust >= 100 ? true : false
    } else if (type === 'trustEe') {
      return trustTotal.trustEe >= 100 ? true : false
    } else if (type === 'trustBene') {
      return trustTotal.trustBene >= 100 ? true : false
    } else if (type === 'successorTrustee') {
      return trustTotal.successorTrustee >= 100 ? true : false
    }
  }

  const deleteRecord = (row: any, i: number) => {
    const participantId = addlParticipantOptions?.find(
      (option: any) =>
        option?.label?.toLowerCase() == row?.values?.relationship?.toLowerCase()
    )?.value

    deleteSelectedRow?.(row, parseInt(participantId ?? '0'))
    deleteRow(row)
  }

  /*
  https://dev.api.wedbush.com/aopexperienceapi/PartyRole/DeleteAccountRole
  {
      "partyid": 0,
      "wipacctid": 0,
      "relationshiptypeid": 0
  }
  */

  return (
    <RootContainer>
      {showPopup && (
        <DeleteConfirmationModal
          deleteDoc={() => {
            deleteRecord(currentRow, currentIndex)
            setShowPopup(false)
          }}
          setShowPopup={setShowPopup}
          message={'Are you sure you want to delete?'}
        />
      )}
      <TableWrapper {...getTableProps()}>
        <TableHead id="retirement">
          {headerGroups.map((headerGroup, index) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, i) => (
                <TableHeaderCell {...column.getHeaderProps()} key={i}>
                  <ThWrapper>
                    <ThContentWrapper className="label_responsive">
                      {column.render('Header')}
                    </ThContentWrapper>
                  </ThWrapper>
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={index} className="table-row">
                {row.cells.map((cell, i) => {
                  if (i === 3 && cell.column.id == 'relationship') {
                    const options: OptionProps[] =
                      type === 'addlPpt'
                        ? addlParticipantOptions
                        : relationshipOptions
                    const rel = options?.find(
                      (item: any) =>
                        item?.label?.replaceAll(' ', '')?.toLowerCase() ==
                        cell?.value?.replaceAll(' ', '')?.toLowerCase()
                    )

                    return (
                      <td key={i}>
                        <SelectDropdown
                          isDisabled={
                            readOnly ||
                            cell.value ===
                              addlParticipantOptions?.find(
                                (item) => item.value === cell.value
                              )?.value
                          }
                          width={'20rem'}
                          options={
                            type === 'addlPpt'
                              ? addlParticipantOptions
                              : relationshipOptions
                          }
                          name={'relationship'}
                          onChange={(option: OptionProps | undefined) => {
                            setSelectedData &&
                              setSelectedData((prev: any) => {
                                const newSelectedData = [...prev]
                                newSelectedData[index] = {
                                  ...newSelectedData[index],
                                  [cell.column.id]: option?.label,
                                }

                                if (type === 'addlPpt') {
                                  setSelectedAddiParticipant?.(newSelectedData)
                                }
                                return newSelectedData
                              })
                          }}
                          borderColor={COLORS.Border.Gray50}
                          borderRadius={'8px'}
                          placeholderColor={COLORS.Text.NeutralTextWeak}
                          padding={'0 8px'}
                          value={rel}
                        />
                      </td>
                    )
                  } else if (i === 5) {
                    return (
                      <td key={i}>
                        {!readOnly && (
                          <span>
                            <ButtonWrapper>
                              <WDButtonTransparent>
                                <Button
                                  type="button"
                                  onClick={() => {
                                    setShowPopup(true)
                                    setCurrentRow(row)
                                    setCurrentIndex(index)
                                  }}
                                  disabled={tableData.length === 1}
                                >
                                  <SvgBin fillColor={COLORS.Icons.Neutral} />
                                </Button>
                              </WDButtonTransparent>
                              <WDButtonTransparent>
                                {parseInt(row.id) + 1 === tableData.length ? (
                                  <Button
                                    type="button"
                                    onClick={handleNewValue}
                                    disabled={
                                      sumValue() || tableData.length === 3
                                    }
                                    prefixedIcon={
                                      <SvgAdd
                                        fillColor={COLORS.Icons.Neutral}
                                      />
                                    }
                                  ></Button>
                                ) : (
                                  ''
                                )}
                              </WDButtonTransparent>
                            </ButtonWrapper>
                          </span>
                        )}
                      </td>
                    )
                  } else if (i === 4) {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={i}
                        className="label_responsive"
                      >
                        <WDTextboxBorderRadius className="inputBox_responsive">
                          <Textbox
                            type="number"
                            disabled={
                              cell.column.id === 'percentageOfBenefits'
                                ? false
                                : true
                            }
                            readOnly={readOnly}
                            onChange={(value) => {
                              setSelectedData &&
                                setSelectedData((prev: any) => {
                                  const newSelectedData = [...prev]
                                  newSelectedData[index] = {
                                    ...newSelectedData[index],
                                    [cell.column.id]: value,
                                  }
                                  if (type === 'addlPpt') {
                                    setSelectedAddiParticipant?.(
                                      newSelectedData
                                    )
                                  }
                                  return newSelectedData
                                })
                            }}
                            value={cell.value}
                          />
                        </WDTextboxBorderRadius>
                      </td>
                    )
                  } else {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={i}
                        className="label_responsive"
                      >
                        <WDTextboxBorderRadius className="inputBox_responsive">
                          <Textbox
                            disabled={
                              cell.column.id === 'percentageOfBenefits'
                                ? false
                                : true
                            }
                            readOnly={readOnly}
                            onChange={(value) => {
                              setSelectedData &&
                                setSelectedData((prev: any) => {
                                  const newSelectedData = [...prev]
                                  newSelectedData[index] = {
                                    ...newSelectedData[index],
                                    [cell.column.id]: value,
                                  }
                                  if (type === 'addlPpt') {
                                    setSelectedAddiParticipant?.(
                                      newSelectedData
                                    )
                                  }
                                  return newSelectedData
                                })
                            }}
                            value={cell.value}
                          />
                        </WDTextboxBorderRadius>
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </TableBody>
        {hasFooter ? (
          <tfoot>
            {footerGroups.map((group, index) => (
              <tr {...group.getFooterGroupProps()} key={index}>
                {group.headers.map((column, i) => (
                  <td {...column.getFooterProps()} key={i}>
                    <WDTextboxBorderRadius className="inputBox_responsive">
                      <Textbox
                        disabled={readOnly}
                        onChange={(value) => {
                          console.log(value)
                        }}
                      />
                    </WDTextboxBorderRadius>
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        ) : null}
      </TableWrapper>
    </RootContainer>
  )
}

export default RetirementTable
