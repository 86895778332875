import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAccount, useMsal } from '@azure/msal-react'
import { protectedResources } from '../settings/authConfig'
import { IOConnectContext } from '@interopio/react-hooks'
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser'
import useIsInteropEnv from './useIsInteropEnv'

interface AuthCtxProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

interface IDTokenInterface {
  Username: string
  at_hash: string
  aud: string
  auth_time: number
  email: string
  exp: number
  family_name: string
  given_name: string
  iat: number
  idp: string
  iss: string
  name: string
  nbf: number
  nonce: string
  sub: string
  tid: string
  upn: string
  ver: string
  roles: RolesInterface
}

export interface RolesInterface {
  Entitlements: string[] | null
  OrgRoles: string[] | null
  SegRoles: string[] | null
}
export interface AuthCtxProviderInterface {
  accessToken: string
  b2cRefreshToken?: () => void
  loggedUser: string
  loginRoles: RolesInterface
  // userRole: string
  setLoginRoles: React.Dispatch<React.SetStateAction<RolesInterface>>
}

export const AuthContext = createContext<AuthCtxProviderInterface>({
  accessToken: '',
  loggedUser: '',
  loginRoles: {} as RolesInterface,
  // userRole: '',
  setLoginRoles: () => ({} as RolesInterface),
})

function AuthContextProvider({ children }: AuthCtxProviderProps) {
  const { instance, accounts, inProgress } = useMsal()
  const io = useContext(IOConnectContext)
  const [ioAccount, setIoAccount] = useState<AccountInfo | null>(null)
  const { isInteropEnv } = useIsInteropEnv()
  const account = useAccount(accounts[0] || {})
  const [accessToken, setAccessToken] = useState('')
  const [loggedUser, setLoggedUser] = useState<string>('')
  const userName = account?.idTokenClaims?.Username?.toString()
  const index = userName?.indexOf('@')
  sessionStorage.setItem('userName', userName?.slice(0, index).toString() ?? '')
  sessionStorage.setItem(
    'AvatarName',
    account?.idTokenClaims?.name?.toString() ?? ''
  )
  const [loginRoles, setLoginRoles] = useState<RolesInterface>({
    Entitlements: [],
    OrgRoles: [],
    SegRoles: [],
  })

  useEffect(() => {
    async function b2cAuth() {
      if (isInteropEnv) {
        await io?.contexts?.subscribe(
          'AIW_SSO_AUTH_RESPONSE',
          (data: AuthenticationResult) => {
            const idTokenDetails = data?.idTokenClaims as IDTokenInterface
            if (idTokenDetails?.roles) {
              const getRolesData = JSON.parse(idTokenDetails.roles.toString())
              setLoginRoles({
                ...loginRoles,
                OrgRoles: getRolesData.OrgRoles,
                SegRoles: getRolesData.SegRoles,
                // Entitlements: getRolesData.Entitlements,
              })
              // setLoginRoles(getRolesData)
              // if (
              //   getRolesData?.Entitlements?.find((role: string) =>
              //     role?.startsWith('AO')
              //   )
              // )
              //   setUserRole(
              //     getRolesData?.Entitlements?.find((role: string) =>
              //       role?.startsWith('AO')
              //     )
              //   )
            }
            setAccessToken(data.accessToken)
            setLoggedUser(data?.account?.name?.toString() ?? '')
            setIoAccount(data?.account)
            localStorage.setItem('AccessToken', data.accessToken)
          }
        )
      } else if (account && inProgress === 'none') {
        localStorage.setItem('AcctInfo', JSON.stringify(account))
        instance
          .acquireTokenSilent({
            scopes: protectedResources.api.scopes,
            account: account,
          })
          .then((response: any) => {
            const idTokenDetails = response.idTokenClaims as IDTokenInterface
            if (idTokenDetails?.roles) {
              const getRolesData = JSON.parse(idTokenDetails.roles.toString())
              setLoginRoles({
                ...loginRoles,
                OrgRoles: getRolesData.OrgRoles,
                SegRoles: getRolesData.SegRoles,
              })
              // setLoginRoles(getRolesData)
              // if (getRolesData.Entitlements.length > 0)
              //   setUserRole(getRolesData.Entitlements[0])
            }
            setAccessToken(response.accessToken)
            setLoggedUser(response.idTokenClaims.name)
            // localStorage.removeItem('AccessToken')
            localStorage.setItem('AccessToken', response.accessToken)
          })
      }
    }
    b2cAuth()
  }, [io?.contexts, account, inProgress, instance])

  async function b2cRefreshToken() {
    if (account) {
      const newRequestToken = await instance.acquireTokenSilent({
        scopes: protectedResources.api.scopes,
        account: account,
        forceRefresh: true,
      })
      localStorage.setItem('AccessToken', newRequestToken.accessToken)
      setAccessToken(newRequestToken.accessToken)
      return newRequestToken.accessToken
    }
  }

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        b2cRefreshToken,
        loggedUser,
        loginRoles,
        setLoginRoles,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
