import { ReactElement, useState } from 'react'
import Button from '../../components/button/Button'
import CustomCalendar from '../../components/customCalendar/CustomCalendar'
import Divider from '../../components/divider/Divider'
import Label from '../../components/label/Label'
import { Textbox } from '../../components/textbox/Textbox'
import {
  WDButton,
  WDButtonGrayTransparent,
  WDButtonPrimaryTransparent,
  WDButtonTransparent,
} from '../../components/ui/WDButton'
import {
  WDLabel,
  WDLabelClient,
  WDLabelPrimaryTextSmall,
  WDLabelSemiBold,
  WDLabelStyledCardHeader,
} from '../../components/ui/WDLabel'
import {
  WDCalendar,
  WDTextboxBorderRadius,
} from '../../components/ui/WDTextbox'
import { GetDashboardDetails } from '../../services/Dashboard/DashboardApi'
import { COLORS } from '../../theme/Colors'
import { ModelButtonWrapper } from '../previewFile/styles'
import { OptionProps } from '../selectDropdown/ISelectDropDown'
import SelectDropdown from '../selectDropdown/SelectDropdown'
import {
  ButtonWrapper,
  DashboardContentWrapperOne,
  MasterWrapperModel,
  ModalWrapper,
  ModelContent,
  NoDataWrap,
  OverlayWrapper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TextBox,
  TextBoxButton,
  Wrapper,
  WrapperOne,
  WrapperTwo,
} from './styles'
import Loader from '../../assets/lottiefiles/loader.json'
import { SvgCancelLarge } from '../../components/svg/SvgCancelLarge'
import { DividerWrapper } from '../forms/formWip/styles'
import ApprovalPopup from './approvalPopup/ApprovalPopup'
import { TextBoxWrapper } from './approvalPopup/styles'
import { SpinnerWrapper } from '../interceptor/styles'
import { LottieWrapper } from './../viewDocument/styles'
import Lottie from 'lottie-react'
import { SvgNoData } from '../../components/svg/SvgNoData'

export interface RequestData {
  requestId: number
  requestPayload: string
  status: string
  accountErrorMessage: string
  profileErrorMessage: string
  requestedBy: string
  accountNumber: string
  requestedDate: string
  updatedDate: string
  correlationId: string | null
  accountId: string
  approvedBy: string | null
  approvedDate: string | null
  comment: string | null
}

const NestedInputField = ({ name, value }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem',
      }}
    >
      <WDLabelStyledCardHeader>{name}</WDLabelStyledCardHeader>
      <DividerWrapper>
        <Divider
          horizontal={true}
          bgColor={`${COLORS.Background.Neutral}`}
          thickness={'0.1rem'}
        />
      </DividerWrapper>
      {Object.keys(value).map((key) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <InputField key={key} name={key} value={value[key]} />
      ))}
    </div>
  )
}

const InputField = ({ name, value }: any): ReactElement => {
  if (Array.isArray(value)) {
    if (value.length && typeof value[0] == 'string') {
      return (
        <>
          {value.map((item, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                gap: '10px',
                width: '50%',
                justifyContent: 'space-between',
              }}
            >
              <WDLabelClient>{name}</WDLabelClient>
              <TextBoxWrapper>
                <input type="text" value={value} />
              </TextBoxWrapper>
            </div>
          ))}
        </>
      )
    } else {
      return (
        <>
          {value.map((item, index) => (
            <div key={index}>
              <NestedInputField name={name} value={item} />
            </div>
          ))}
        </>
      )
    }
  }

  if (typeof value === 'object') {
    return <NestedInputField name={name} value={value} />
  }

  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
        width: '50%',
        justifyContent: 'space-between',
      }}
    >
      <WDLabelClient>{name}</WDLabelClient>
      <TextBoxWrapper>
        <input type="text" value={value} />
      </TextBoxWrapper>
    </div>
  )
}

const NewDashboard = () => {
  const [formData, setFormData] = useState({
    fromDate: '',
    toDate: '',
    status: '',
    requestedBy: '',
  })
  const [loading, setLoading] = useState(false)
  const [isFormData, setIsFormData] = useState(false)
  const [commentModal, setCommentModal] = useState(false)
  const [requestID, setRequestID] = useState<RequestData>({} as RequestData)
  const [isApprove, setIsapprove] = useState(false)
  const [isKYCPass, setKYCPass] = useState(false)
  const [isPRDApproved, setPRDApproved] = useState(false)
  const [header, setHeader] = useState('')
  const [clearDates, setClearDates] = useState(false)
  const [status, setStatus] = useState('')
  const [data, setData] = useState<RequestData[]>([])

  const formatDate = (dateString: string | Date) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const formattedDate = `${month}-${day}-${year}`
    return formattedDate
  }
  const getDetailsAPICall = () => {
    setLoading(true)
    GetDashboardDetails(
      formatDate(formData.fromDate),
      formatDate(formData.toDate),
      formData.status,
      formData.requestedBy
    )
      .then((res: any) => {
        setData(res.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const [selectedData, setSelectedData] = useState<any | null>(null)

  const [showPopup, setShowPopup] = useState(false)

  const handleShowResponse = (responseData: any) => {
    setSelectedData(responseData)
    setShowPopup(true)
  }

  return (
    <Wrapper>
      {showPopup && (
        <MasterWrapperModel>
          <OverlayWrapper>
            <ModalWrapper>
              <ModelButtonWrapper>
                <WDButtonGrayTransparent>
                  <Button type={'button'} onClick={() => setShowPopup(false)}>
                    <SvgCancelLarge fillColor={COLORS.UI.Gray} />
                  </Button>
                </WDButtonGrayTransparent>
              </ModelButtonWrapper>

              <Divider
                horizontal={true}
                bgColor={`${COLORS.Border.Gray110}`}
                thickness={'0.0625rem'}
              />

              <ModelContent>
                <WDLabelStyledCardHeader>
                  {'Response Data'}
                </WDLabelStyledCardHeader>
                <NestedInputField name="" value={JSON.parse(selectedData)} />
              </ModelContent>
            </ModalWrapper>
          </OverlayWrapper>
        </MasterWrapperModel>
      )}

      <DashboardContentWrapperOne>
        <WrapperTwo>
          <TextBox>
            <WDLabel>
              <Label>{'From Date'}</Label>
            </WDLabel>

            <WDCalendar>
              <CustomCalendar
                name="fromDate"
                placeholder="From date"
                onChange={(e) => {
                  setIsFormData(true)

                  const date = new Date(e)
                  const UtcDate = date.toUTCString()

                  if (UtcDate != '') {
                    setIsFormData(true)
                    setClearDates(false)
                  }
                  setFormData({
                    ...formData,
                    [`fromDate`]: UtcDate,
                  })
                  //setValidationMessage('')
                }}
                // disabled={!isButtonValid}
                // resetValue={!clearDates}
                value={formData.fromDate}
              />
            </WDCalendar>
          </TextBox>
          <TextBox>
            <WDLabel>
              <Label>{'To Date'}</Label>
            </WDLabel>

            <WDCalendar>
              <CustomCalendar
                name="toDate"
                placeholder="To date"
                onChange={(e) => {
                  setIsFormData(true)

                  const date = new Date(e)
                  const UtcDate = date.toUTCString()

                  if (UtcDate != '') {
                    setIsFormData(true)
                    setClearDates(false)
                  }
                  setFormData({
                    ...formData,
                    [`toDate`]: UtcDate,
                  })
                  //setValidationMessage('')
                }}
                // disabled={!isButtonValid}
                // resetValue={!clearDates}
                value={formData.toDate}
              />
            </WDCalendar>
          </TextBox>
          <TextBox>
            <WDLabel>
              <Label>{'Status'}</Label>
            </WDLabel>

            <SelectDropdown
              width="200px"
              height="1rem"
              options={[
                { label: 'Submitted', value: '1' },
                { label: 'Success', value: '2' },
                { label: 'KYC - Failed', value: '3' },
                { label: 'KYC - Approved', value: '4' },
                { label: 'PRD - Approved', value: '5' },
                { label: 'PRD - Failed', value: '6' },
              ]}
              name="status"
              isSearchable={false}
              onChange={(option: OptionProps | undefined) => {
                //
                const value = option ? option.label : ''
                if (value != '') {
                  setFormData({
                    ...formData,
                    [`status`]: value,
                  })
                }
              }}
            />
          </TextBox>
          <TextBox>
            <WDLabel>
              <Label> {'Requested by'}</Label>
            </WDLabel>
            <WDTextboxBorderRadius>
              <Textbox
                placeholder={'Requested by'}
                type={'text'}
                name="nickName"
                value={formData.requestedBy}
                onChange={(e) => {
                  if (e != '') {
                    setFormData({
                      ...formData,
                      [`requestedBy`]: e,
                    })
                  }
                }}
              />
            </WDTextboxBorderRadius>
          </TextBox>
          <TextBoxButton>
            <WDButton>
              <Button
                type={'button'}
                onClick={() => {
                  //
                  getDetailsAPICall()
                }}
                hover={true}
              >
                {`Search`}
              </Button>
            </WDButton>
          </TextBoxButton>
        </WrapperTwo>
        <WrapperOne>
          {loading ? (
            <NoDataWrap>
              {' '}
              <SpinnerWrapper>
                <LottieWrapper>
                  <Lottie animationData={Loader} loop={true} />
                </LottieWrapper>
              </SpinnerWrapper>
            </NoDataWrap>
          ) : data?.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>Request ID</TableHeader>
                  <TableHeader>Response Data</TableHeader>
                  <TableHeader>Requested By</TableHeader>
                  <TableHeader>Account Number</TableHeader>
                  <TableHeader>Approved By</TableHeader>
                  <TableHeader>Approved Date</TableHeader>
                  <TableHeader>Comments</TableHeader>
                  <TableHeader>KYC Approved By</TableHeader>
                  <TableHeader>KYC Approved Date</TableHeader>
                  <TableHeader>KYC Comments</TableHeader>
                  <TableHeader>Requested Date</TableHeader>
                  <TableHeader>Updated Date</TableHeader>
                  <TableHeader>Status</TableHeader>
                  <TableHeader>Action</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item: any, index) => (
                  <TableRow key={item.requestId}>
                    <TableCell>{item.requestId}</TableCell>
                    <TableCell>
                      <WDButtonTransparent>
                        <Button
                          type={'button'}
                          onClick={() =>
                            handleShowResponse(item.requestPayload)
                          }
                        >
                          <WDLabelPrimaryTextSmall>
                            Show
                          </WDLabelPrimaryTextSmall>
                        </Button>
                      </WDButtonTransparent>
                    </TableCell>

                    <TableCell>{item.requestedBy}</TableCell>
                    <TableCell>{item.accountNumber}</TableCell>
                    <TableCell>{item.approvedBy}</TableCell>
                    <TableCell>
                      {item.approvedDate ? formatDate(item.approvedDate) : ''}
                    </TableCell>
                    <TableCell>{item.comment}</TableCell>
                    <TableCell>{item.kycCompletedBy}</TableCell>
                    <TableCell>
                      {item.kycCompletedDate
                        ? formatDate(item.kycCompletedDate)
                        : ''}
                    </TableCell>
                    <TableCell>{item.kycComment}</TableCell>
                    <TableCell>{formatDate(item.requestedDate)}</TableCell>
                    <TableCell>{formatDate(item.updatedDate)}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>
                      {item.status === 'Success' && (
                        <ButtonWrapper>
                          <WDButtonPrimaryTransparent>
                            <Button
                              type={'Button'}
                              onClick={() => {
                                setStatus(item.status)
                                setKYCPass(true)
                                setRequestID(item)
                                setCommentModal(true)
                                setHeader('KYC Pass')
                              }}
                            >
                              KYC Pass
                            </Button>
                          </WDButtonPrimaryTransparent>
                          <WDButtonPrimaryTransparent>
                            <Button
                              type={'Button'}
                              onClick={() => {
                                setStatus(item.status)
                                setKYCPass(false)
                                setRequestID(item)
                                setCommentModal(true)
                                setHeader('KYC Fail')
                              }}
                            >
                              KYC Fail
                            </Button>
                          </WDButtonPrimaryTransparent>
                        </ButtonWrapper>
                      )}
                      {item.status === 'KYC - Failed' && (
                        <ButtonWrapper>
                          <WDButtonPrimaryTransparent>
                            <Button
                              type={'Button'}
                              onClick={() => {
                                setStatus(item.status)
                                setKYCPass(true)
                                setRequestID(item)
                                setCommentModal(true)
                                setHeader('KYC Pass')
                              }}
                            >
                              KYC Pass
                            </Button>
                          </WDButtonPrimaryTransparent>
                        </ButtonWrapper>
                      )}
                      {item.status === 'KYC - Approved' && (
                        <ButtonWrapper>
                          <WDButtonPrimaryTransparent>
                            <Button
                              type={'Button'}
                              onClick={() => {
                                setStatus(item.status)
                                setPRDApproved(true)
                                setRequestID(item)
                                setCommentModal(true)
                                setHeader('PRD-Approve')
                              }}
                            >
                              PRD-Approve
                            </Button>
                          </WDButtonPrimaryTransparent>
                          <WDButtonPrimaryTransparent>
                            <Button
                              type={'Button'}
                              onClick={() => {
                                setStatus(item.status)
                                setPRDApproved(false)
                                setRequestID(item)
                                setCommentModal(true)
                                setHeader('PRD-Failed')
                              }}
                            >
                              PRD-Failed
                            </Button>
                          </WDButtonPrimaryTransparent>
                        </ButtonWrapper>
                      )}
                      {item.status === 'PRD - Failed' && (
                        <ButtonWrapper>
                          <WDButtonPrimaryTransparent>
                            <Button
                              type={'Button'}
                              onClick={() => {
                                setPRDApproved(true)
                                setRequestID(item)
                                setCommentModal(true)
                                setHeader('PRD Approve')
                              }}
                            >
                              PRD Approve
                            </Button>
                          </WDButtonPrimaryTransparent>
                        </ButtonWrapper>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoDataWrap>
              <SvgNoData />
              <WDLabelSemiBold>{'No Results Found'}</WDLabelSemiBold>
            </NoDataWrap>
          )}
        </WrapperOne>
      </DashboardContentWrapperOne>
      {commentModal && (
        <ApprovalPopup
          setCommentModal={setCommentModal}
          isKYCPass={isKYCPass}
          isPRDApproved={isPRDApproved}
          status={status}
          request={requestID}
          header={header}
          setData={setData}
        />
      )}
    </Wrapper>
  )
}

export default NewDashboard
