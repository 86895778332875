import { FC, lazy, Suspense, useContext, useEffect, useState } from 'react'
import './App.css'
import { Routes, Route, Navigate } from 'react-router-dom'

const HomePage = lazy(() => import('./pages/HomePage'))
const Home = lazy(() => import('./pages/home'))
import ReservedAccountNumberPage from './composites/forms/getReservedAccountNumber/ReservedAccountNumber'
// import AuthContextProvider from './services/AuthContext'
import ThankYouPage from './composites/thankYouPage/ThankYouPage'
import SubmittedForm from './composites/submittedForm/SubmittedForm'
import AccountApplicationGrid from './pages/accountApplicationPage/AccountApplicationGrid'
import ReviewPdfTemplate from './pages/PdfTemplate/ReviewPdfTemplate'
import NewDashboard from './composites/newDashboard/NewDashboard'
import NewGrid from './pages/accountApplicationPage/NewGrid'
import ApprovalsPage from './composites/forms/disclosurePage/DisclosurePage'
import { RoleContext } from './services/roleContext/RoleContext'
import Retirement from './composites/forms/retirementForm/Retirement'
import TrustForm from './composites/forms/trustForms/TrustForm'
// import { ApplicationPageContextProvider } from './services/applicationContext/ApplicationPageContext'

const AppRoutes: FC = () => {
  const { accessList } = useContext(RoleContext)
  const dashboardUser = [
    {
      email: 'CHRISTINA.FILLHART@WEDBUSH.COM',
    },
    {
      email: 'COLE.THOMPSON@WEDBUSH.COM',
    },
    {
      email: 'ROBERT.CAMACHO@WEDBUSH.COM',
    },
    {
      email: 'PRASHANT.SHAH@WEDBUSH.COM',
    },
    {
      email: 'CINDY.BEADLES@WEDBUSH.COM',
    },
    {
      email: 'SHARON.SITU@WEDBUSH.COM',
    },
    {
      email: 'OSCAR.ONYENACHO@WEDBUSH.COM',
    },
    {
      email: 'HAMZA.MAQSODI@WEDBUSH.COM',
    },
    {
      email: 'SELVADEEPA.N@WEDBUSH.COM',
    },
    {
      email: 'SUMIT.SARASWAT@WEDBUSH.COM',
    },
    {
      email: 'NARESH.DOIJODE@WEDBUSH.COM',
    },
  ]

  const getMatchedUser = (user: string) => {
    let matched = false
    const UserEmail = user?.trim().toUpperCase()
    dashboardUser.map((item) => {
      if (item.email.includes(UserEmail)) {
        matched = true
      }
    })
    return matched
  }
  const [getUser, setGetUser] = useState<boolean>(false)

  useEffect(
    () => {
      const accountInfo = localStorage.getItem('AcctInfo') || ''
      if (
        accountInfo &&
        getMatchedUser(JSON.parse(accountInfo).idTokenClaims.email)
      ) {
        // JSON.parse(accountInfo).idTokenClaims.email === dashboardUser[0].email
        setGetUser(true)
      } else {
        setGetUser(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    // <AuthContextProvider>
    //   <ApplicationPageContextProvider>
    <Suspense>
      <Routes>
        {getUser && <Route path="/" element={<Navigate to="/dashboard" />} />}
        <Route element={<HomePage isDashboardUser={!getUser} />}>
          {accessList.home && (
            <Route path="/" element={<Navigate to="/home" />} />
          )}
          {accessList.dashboard && (
            <Route path="/dashboard" element={<NewDashboard />} />
          )}
          {accessList.home && <Route path="/home" element={<Home />} />}
          <Route path="/account-application" element={<NewGrid />} />
          <Route path="/grid" element={<AccountApplicationGrid />} />
          <Route path="/resnum" element={<ReservedAccountNumberPage />} />
          <Route path="/thankYou" element={<ThankYouPage />} />
          <Route path="/pendingPage" element={<SubmittedForm />} />
          <Route path="/ReviewPdfTemplate" element={<ReviewPdfTemplate />} />
          <Route path="/retirement" element={<Retirement />} />
          <Route path="/trust" element={<TrustForm />} />
          {accessList.approvals && (
            <Route path="/approvals" element={<ApprovalsPage />} />
          )}
        </Route>
      </Routes>
    </Suspense>
    //   </ApplicationPageContextProvider>
    // </AuthContextProvider>
  )
}

export default AppRoutes
