import { COLORS } from '../../theme/Colors'
import Divider from '../../components/divider/Divider'
import { ProgressStateProps } from './IProgressBar'
import { WDLabelClient } from '../../components/ui/WDLabel'
import Button from '../../components/button/Button'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import {
  Container,
  ProgressWrapper,
  Progressbar,
  StageNameWrapper,
  StageNumberWrapper,
  StageWrapper,
  StepWrapper,
  DividerWrapper,
} from './styles'
import { SvgRightTick } from '../../components/svg/SvgRightTick'
import { ApplicationInfoContext } from '../../services/applicationContext/ApplicationPageContext'
import { useContext } from 'react'
import { ResponsiveWrapper } from '../../pages/accountApplicationPage/styles'
import { SvgExclamation } from '../../components/svg/SvgExclamation'
import { ApprovalContext } from '../../services/approvalScreenContext/ApprovalContext'
import { StageNameInterface } from '../../services/applicationContext/IApplicationPageContext'
import ToastContext from '../../services/toast/ToastContext'
import { DisclosureContext } from '../../services/disclosureContext/DisclosureContext'

const ProgressBar = ({ stage }: ProgressStateProps) => {
  const {
    // getWipResponse,
    stageNames,
    saveAll,
    activeStage,
    setStage,
    setActiveStage,
    uploadedFiles,
    selectedClient,
    newModelData,
  } = useContext(ApplicationInfoContext)
  const { setToast } = useContext(ToastContext)
  const { getResponse, disableCondition, disableConditionDocuments } =
    useContext(DisclosureContext)
  const { cardsStatus: tempStageData, isRecalled } = useContext(ApprovalContext)
  const allStages: StageNameInterface[] = [
    {
      name: 'Account Information',
      stage: 0,
      validateCards: [
        'productFeatures',
        'contact',
        'investment',
        'electronic',
        'funding',
        'trusted',
      ],
    },
    {
      name: 'Client Documents',
      stage: 1,
      validateCards: ['docs'],
    },
    {
      name: 'Review',
      stage: 2,
    },
    {
      name: 'Client Review',
      stage: 3,
    },
    {
      name: 'Final Review',
      stage: 4,
    },
  ]
  const noMatch = (arr1: string[], arr2: string[]) => {
    return arr1?.every((item: string) => !arr2?.includes(item))
  }
  const areAllTrue = (stage: any) => {
    if (!stage) return true
    else if (stage.name === 'Review') {
      if (tempStageData.length > 0) return false
      else return true
    }

    return noMatch(tempStageData, stage.validateCards)
  }

  const getStatus = (stage: number) => {
    if (activeStage === stage) {
      return 'inprogress'
    } else if (
      activeStage > stage &&
      (areAllTrue(allStages[stage]) ||
        newModelData?.wipDetails?.wipstatusid === 7 ||
        Object.keys(newModelData).length === 0)
    ) {
      return 'completed'
    } else if (activeStage > stage) {
      return 'pending'
    } else {
      return 'not initiated'
    }
  }

  const checkButtonDisable = () => {
    if (
      stageNames[stage].name === 'Account Agreements' &&
      newModelData?.wipDetails?.wipstatusid === 8 &&
      !disableCondition()
    ) {
      return true
    } else if (
      stageNames[stage].name === 'Client Documents' &&
      newModelData?.wipDetails?.wipstatusid === 8 &&
      !disableConditionDocuments()
    ) {
      return true
    } else if (
      stageNames[stage].name === 'Final Review' &&
      newModelData?.wipDetails?.wipstatusid === 8
    ) {
      return true
    }
    return false
    // const isPrimaryFile = uploadedFiles.filter(
    //   (item) => !item.iscoapplicant && !item.isdisclosure
    // )
    // const isCoApplicantFile = uploadedFiles.filter(
    //   (item) => item.iscoapplicant && !item.isdisclosure
    // )
    // if (newModelData?.wipAccountDetailslist?.[0].accttitle === 'Joint') {
    //   if (isPrimaryFile.length > 0 && isCoApplicantFile.length > 0) return false
    //   else return true
    // } else if (isPrimaryFile.length > 0) {
    //   return false
    // } else return true
  }

  // useEffect(() => {
  //   if (getWipResponse?.wipDTO?.appstatus === 'PC') {
  //     setActiveStage(3)
  //   } else if (getWipResponse?.wipDTO?.wipstatus === '8') setActiveStage(2)
  //   else setActiveStage(stage)
  // }, [getWipResponse?.wipDTO?.appstatus, stage])
  return (
    <ResponsiveWrapper id="common">
      <Container>
        <Progressbar>
          {stageNames
            .filter((stage: any) => {
              // return stage
              if (newModelData?.wipDetails?.wipstatusid === 8) {
                return stage.stage + 1
              } else if (newModelData?.wipDetails?.wipstatusid !== 8) {
                if (newModelData?.wipDetails?.wipstatusid === 7) {
                  if (
                    newModelData?.wipAccountDetailslist &&
                    newModelData?.wipAccountDetailslist.length > 0 &&
                    (newModelData?.wipAccountDetailslist[0]?.accttitle ===
                      'Retirement' ||
                      newModelData?.wipAccountDetailslist[0]?.accttitle ===
                        'Trust' ||
                      newModelData?.wipAccountDetailslist[0]?.accttitle ===
                        'Entity/Corporation')
                  ) {
                    return stage.stage < 5
                  } else return stage.stage < 4
                } else if (
                  newModelData?.wipDetails?.wipstatusid === 9 ||
                  newModelData?.wipDetails?.wipstatusid === 6
                )
                  return stage.stage + 1
                else if (
                  newModelData?.wipAccountDetailslist &&
                  newModelData?.wipAccountDetailslist.length > 0 &&
                  (newModelData?.wipAccountDetailslist[0]?.accttitle ===
                    'Retirement' ||
                    newModelData?.wipAccountDetailslist[0]?.accttitle ===
                      'Trust' ||
                    newModelData?.wipAccountDetailslist[0]?.accttitle ===
                      'Entity/Corporation')
                )
                  return stage.stage < 4
                else return stage.stage < 3
              } else if (
                newModelData?.wipAccountDetailslist &&
                newModelData?.wipAccountDetailslist.length > 0 &&
                (newModelData?.wipAccountDetailslist[0]?.accttitle ===
                  'Retirement' ||
                  newModelData?.wipAccountDetailslist[0]?.accttitle ===
                    'Trust' ||
                  newModelData?.wipAccountDetailslist[0]?.accttitle ===
                    'Entity/Corporation')
              )
                return stage.stage < 4
              else return stage.stage < 3
            })
            .map((stage: any) => {
              return (
                <ProgressWrapper key={stage.stage}>
                  <StepWrapper>
                    <StageWrapper>
                      <StageNameWrapper
                        className={
                          newModelData?.wipDetails?.wipstatusid == 8 &&
                          stage.name == 'Account Information'
                          // ? 'disable-btn'
                          // : ''
                        }
                      >
                        <WDButtonTransparent>
                          <Button
                            type="button"
                            disabled={
                              (newModelData?.wipDetails?.wipstatusid === 7 &&
                                stage.stage < 3) ||
                              (!newModelData?.wipDetails?.wipstatusid &&
                                stage.stage === 4) ||
                              (newModelData?.wipDetails?.wipstatusid === 8 &&
                                stage.stage < 3 &&
                                isRecalled) ||
                              (newModelData?.wipDetails?.wipstatusid === 8 &&
                                stage.stage < 4 &&
                                (stage.name == 'Review' ||
                                  stage.name == 'Account Agreements') &&
                                getResponse.filter(
                                  (item) =>
                                    item.partyid === selectedClient?.clientId &&
                                    item.ischecked === true
                                ).length !== getResponse.length) ||
                              (newModelData?.wipDetails?.wipstatusid === 8 &&
                                (stage.name == 'Account Information' ||
                                  stage.name == 'Account Agreements' ||
                                  stage.name == 'Review' ||
                                  stage.name == 'Client Documents' ||
                                  stage.name == 'Final Review') &&
                                checkButtonDisable() &&
                                stage.stage !== activeStage)
                            }
                            onClick={() => {
                              if (
                                newModelData?.wipDetails?.wipstatusid === 8 &&
                                stage.stage == 0
                              ) {
                                return
                              }
                              // if (stage.name == 'Client Documents') {
                              //   if (
                              //     newModelData &&
                              //     newModelData.wipAccountDetailslist &&
                              //     (newModelData?.wipAccountDetailslist[0]
                              //       ?.accttitle === 'Retirement' ||
                              //       newModelData?.wipAccountDetailslist[0]
                              //         ?.accttitle === 'Trust' ||
                              //       newModelData?.wipAccountDetailslist[0]
                              //         ?.accttitle === 'Entity/Corporation')
                              //   ) {
                              //     setStage(stage.stage)
                              //     setActiveStage(stage.stage)
                              //     saveAll()
                              //   } else {
                              //     setStage(stage.stage)
                              //     setActiveStage(stage.stage)
                              //   }
                              // } else {
                              //   setStage(stage.stage)
                              //   setActiveStage(stage.stage)
                              // }
                              if (stage.name == 'Client Documents') {
                                console.log('stage.stage', stage.stage)
                                setStage(stage.stage)
                                setActiveStage(stage.stage)
                                saveAll()
                              } else {
                                if (
                                  newModelData &&
                                  newModelData.wipAccountDetailslist &&
                                  (newModelData?.wipAccountDetailslist[0]
                                    ?.accttitle === 'Retirement' ||
                                    newModelData?.wipAccountDetailslist[0]
                                      ?.accttitle === 'Trust' ||
                                    newModelData?.wipAccountDetailslist[0]
                                      ?.accttitle === 'Entity/Corporation') &&
                                  stage.name !== 'Account Information'
                                ) {
                                  setStage(stage.stage)
                                  setActiveStage(stage.stage)
                                  saveAll()
                                } else {
                                  setStage(stage.stage)
                                  setActiveStage(stage.stage)
                                }
                              }
                            }}
                          >
                            <WDLabelClient className="label_responsive">
                              {stage.name}
                            </WDLabelClient>
                          </Button>
                        </WDButtonTransparent>
                      </StageNameWrapper>
                      <StageNumberWrapper>
                        {getStatus(stage.stage) === 'completed' && (
                          <SvgRightTick fillColor={COLORS.Icons.Success} />
                        )}
                        {getStatus(stage.stage) === 'pending' && (
                          <SvgExclamation fillColor={'#f1c40f'} />
                        )}
                      </StageNumberWrapper>
                    </StageWrapper>
                    <DividerWrapper>
                      <Divider
                        horizontal={true}
                        bgColor={
                          getStatus(stage.stage) === 'inprogress'
                            ? COLORS.Background.PrimaryText
                            : getStatus(stage.stage) === 'completed'
                            ? COLORS.Icons.Success
                            : getStatus(stage.stage) === 'pending'
                            ? '#f1c40f'
                            : COLORS.Background.Gray50
                        }
                        thickness="4px"
                      />
                    </DividerWrapper>
                  </StepWrapper>
                </ProgressWrapper>
              )
            })}
        </Progressbar>
      </Container>
    </ResponsiveWrapper>
  )
}

export default ProgressBar
