import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { AccessList } from './accessList'
import { AuthContext } from '../AuthContext'
import { getUserEntitlement } from '../applicationContext/ApplicationAPI'

interface ContextProps {
  children?: ReactNode
}
interface Root {
  createWip: boolean
  deleteWip: boolean
  updateWip: boolean
  productFeatures: boolean
  contactInfo: boolean
  invProfile: boolean
  elecPreferences: boolean
  funding: boolean
  trustedContact: boolean
  uploadDocuments: boolean
  deleteDocs: boolean
  viewDocs: boolean
  submitApproval: boolean
  approveOptions: boolean
  approveWip: boolean
  sendToClient: boolean
  recall: boolean
  home: boolean
  dashboard: boolean
  accountInfo: boolean
  documents: boolean
  review: boolean
  approvals: boolean
}

export interface RoleInterface {
  accessList: Root
  noRole: boolean
  loader: boolean
}

export const RoleContext = createContext<RoleInterface>({} as RoleInterface)

export const RoleContextProvider = ({ children }: ContextProps) => {
  const [accessList, setAccessList] = useState(AccessList)
  const [noRole, setNoRole] = useState(false)
  const [loader, setLoader] = useState(true)
  const { accessToken, setLoginRoles } = useContext(AuthContext)
  const [userRole, setUserRole] = useState('')

  useEffect(() => {
    const fetchUserEntitlement = async () => {
      try {
        const entitlementResponse = await getUserEntitlement()
        const role = entitlementResponse.data.find((role: string) =>
          role.startsWith('AO')
        )
        role && setUserRole(role)
        role && setLoginRoles((prev) => ({ ...prev, Entitlements: [role] }))
      } catch (error) {
        console.log('Error fetching user entitlement', error)
      }
    }

    accessToken && fetchUserEntitlement()
  }, [accessToken])

  useEffect(() => {
    if (userRole) {
      switch (userRole) {
        case 'AO-IT-ADMIN':
          {
            setNoRole(false)
            setAccessList({
              createWip: true,
              deleteWip: true,
              updateWip: true,
              productFeatures: true,
              contactInfo: true,
              invProfile: true,
              elecPreferences: true,
              funding: true,
              trustedContact: true,
              uploadDocuments: true,
              deleteDocs: true,
              viewDocs: true,
              submitApproval: true,
              approveOptions: true,
              approveWip: true,
              sendToClient: true,
              recall: true,
              home: true,
              dashboard: true,
              accountInfo: true,
              documents: true,
              review: true,
              approvals: true,
            })
          }
          break
        case 'AO-WEDBUSH-REPS':
          setAccessList({
            createWip: true,
            deleteWip: true,
            updateWip: true,
            productFeatures: true,
            contactInfo: true,
            invProfile: true,
            elecPreferences: true,
            funding: true,
            trustedContact: true,
            uploadDocuments: true,
            deleteDocs: true,
            viewDocs: true,
            submitApproval: true,
            approveOptions: false,
            approveWip: false,
            sendToClient: true,
            recall: true,
            home: true,
            dashboard: true,
            accountInfo: true,
            documents: true,
            review: true,
            approvals: true,
          })
          break
        case 'AO-WEDBUSH-CSAS':
          setAccessList({
            createWip: true,
            deleteWip: true,
            updateWip: true,
            productFeatures: true,
            contactInfo: true,
            invProfile: true,
            elecPreferences: true,
            funding: true,
            trustedContact: true,
            uploadDocuments: true,
            deleteDocs: true,
            viewDocs: true,
            submitApproval: false,
            approveOptions: false,
            approveWip: false,
            sendToClient: true,
            recall: true,
            home: true,
            dashboard: true,
            accountInfo: true,
            documents: true,
            review: true,
            approvals: true,
          })
          break
        case 'AO-WEALTH-NEW-ACCOUNTS':
          setAccessList({
            createWip: false,
            deleteWip: false,
            updateWip: true,
            productFeatures: true,
            contactInfo: true,
            invProfile: true,
            elecPreferences: true,
            funding: true,
            trustedContact: true,
            uploadDocuments: true,
            deleteDocs: true,
            viewDocs: true,
            submitApproval: true,
            approveOptions: true,
            approveWip: true,
            sendToClient: true,
            recall: true,
            home: true,
            dashboard: true,
            accountInfo: true,
            documents: true,
            review: true,
            approvals: true,
          })
          break
        case 'AO-ACAPS-NEW-ACCOUNTS':
          setAccessList({
            createWip: true,
            deleteWip: true,
            updateWip: true,
            productFeatures: true,
            contactInfo: true,
            invProfile: true,
            elecPreferences: true,
            funding: true,
            trustedContact: true,
            uploadDocuments: true,
            deleteDocs: true,
            viewDocs: true,
            submitApproval: true,
            approveOptions: true,
            approveWip: true,
            sendToClient: false,
            recall: false,
            home: true,
            dashboard: true,
            accountInfo: true,
            documents: true,
            review: true,
            approvals: true,
          })
          break
        case 'AO-WEALTH-PRD-APPROVERS':
          setAccessList({
            createWip: false,
            deleteWip: false,
            updateWip: true,
            productFeatures: true,
            contactInfo: true,
            invProfile: true,
            elecPreferences: true,
            funding: true,
            trustedContact: true,
            uploadDocuments: true,
            deleteDocs: true,
            viewDocs: true,
            submitApproval: true,
            approveOptions: true,
            approveWip: true,
            sendToClient: false,
            recall: false,
            home: true,
            dashboard: true,
            accountInfo: true,
            documents: true,
            review: true,
            approvals: true,
          })
          break
        case 'AO-WEALTH-PORD-APPROVERS':
          setAccessList({
            createWip: false,
            deleteWip: false,
            updateWip: true,
            productFeatures: true,
            contactInfo: true,
            invProfile: true,
            elecPreferences: true,
            funding: true,
            trustedContact: true,
            uploadDocuments: true,
            deleteDocs: true,
            viewDocs: true,
            submitApproval: true,
            approveOptions: true,
            approveWip: true,
            sendToClient: false,
            recall: false,
            home: true,
            dashboard: true,
            accountInfo: true,
            documents: true,
            review: true,
            approvals: true,
          })
          break
        case 'AO-CORRESPONDENT-CSAS':
          setAccessList({
            createWip: true,
            deleteWip: true,
            updateWip: true,
            productFeatures: true,
            contactInfo: true,
            invProfile: true,
            elecPreferences: true,
            funding: true,
            trustedContact: true,
            uploadDocuments: true,
            deleteDocs: true,
            viewDocs: true,
            submitApproval: false,
            approveOptions: false,
            approveWip: false,
            sendToClient: true,
            recall: true,
            home: true,
            dashboard: true,
            accountInfo: true,
            documents: true,
            review: true,
            approvals: true,
          })
          break
        case 'AO-CORRESPONDENT-REPS':
          setAccessList({
            createWip: true,
            deleteWip: true,
            updateWip: true,
            productFeatures: true,
            contactInfo: true,
            invProfile: true,
            elecPreferences: true,
            funding: true,
            trustedContact: true,
            uploadDocuments: true,
            deleteDocs: true,
            viewDocs: true,
            submitApproval: true,
            approveOptions: false,
            approveWip: false,
            sendToClient: false,
            recall: false,
            home: true,
            dashboard: true,
            accountInfo: true,
            documents: true,
            review: true,
            approvals: true,
          })
          break
        case 'AO-CORRESPONDENT-BRANCH-MANAGERS':
          setAccessList({
            createWip: true,
            deleteWip: true,
            updateWip: true,
            productFeatures: true,
            contactInfo: true,
            invProfile: true,
            elecPreferences: true,
            funding: true,
            trustedContact: true,
            uploadDocuments: true,
            deleteDocs: true,
            viewDocs: true,
            submitApproval: true,
            approveOptions: true,
            approveWip: true,
            sendToClient: false,
            recall: false,
            home: true,
            dashboard: true,
            accountInfo: true,
            documents: true,
            review: true,
            approvals: true,
          })
          break
        case 'AO-CORRESPONDENT-APPROVERS':
          setAccessList({
            createWip: true,
            deleteWip: false,
            updateWip: true,
            productFeatures: true,
            contactInfo: true,
            invProfile: true,
            elecPreferences: true,
            funding: true,
            trustedContact: true,
            uploadDocuments: true,
            deleteDocs: true,
            viewDocs: true,
            submitApproval: true,
            approveOptions: true,
            approveWip: true,
            sendToClient: false,
            recall: false,
            home: true,
            dashboard: true,
            accountInfo: true,
            documents: true,
            review: true,
            approvals: true,
          })
          break
        default: {
          console.log('No access!!!!🚀🚀🚀🚀🚀')
          setNoRole(true)
        }
      }
      setLoader(false)
    }
  }, [userRole])

  const values = useMemo(
    () => ({
      accessList,
      noRole,
      loader,
    }),
    [accessList, noRole, loader]
  )
  return <RoleContext.Provider value={values}>{children}</RoleContext.Provider>
}
