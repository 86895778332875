import './App.css'
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
} from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import AppRoutes from './AppRoutes'
import { loginRequest } from './settings/authConfig'
import { useIdleTimer } from 'react-idle-timer'
import AuthContextProvider from './services/AuthContext'
import { LobInfoContextProvider } from './services/LobOfficeCode/LobOfficeContext'
import { RetirementDetailsContextProvider } from './services/Retirement/RetirementContext'
import { ApplicationPageContextProvider } from './services/applicationContext/ApplicationPageContext'
import { ApprovalContextProvider } from './services/approvalScreenContext/ApprovalContext'
import { DisclosureContextProvider } from './services/disclosureContext/DisclosureContext'
import { RoleContextProvider } from './services/roleContext/RoleContext'
import { ToastContextProvider } from './services/toast/ToastContext'
import { IOConnectContext } from '@interopio/react-hooks'
import { useContext } from 'react'
import InteropContextProvider from './services/InteropContextProvider'
import useIsInteropEnv from './services/useIsInteropEnv'
import Interceptor from './composites/interceptor/Interceptor'
function App() {
  const authRequest = {
    ...loginRequest,
  }
  const { instance } = useMsal()
  const { isInteropEnv } = useIsInteropEnv()
  console.log(isInteropEnv, 'check')
  instance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      instance.setActiveAccount(account)
    }
  })
  const onIdle = () => {
    sessionStorage.clear()
    instance.logout()
  }

  useIdleTimer({
    onIdle,
    timeout: 1200000,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  })
  const Main = () => (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <AuthContextProvider>
        <Interceptor />
        <RoleContextProvider>
          <ToastContextProvider>
            <ApprovalContextProvider>
              <LobInfoContextProvider>
                <RetirementDetailsContextProvider>
                  <ApplicationPageContextProvider>
                    <DisclosureContextProvider>
                      <AppRoutes />
                    </DisclosureContextProvider>
                  </ApplicationPageContextProvider>
                </RetirementDetailsContextProvider>
              </LobInfoContextProvider>
            </ApprovalContextProvider>
          </ToastContextProvider>
        </RoleContextProvider>
      </AuthContextProvider>
    </MsalAuthenticationTemplate>
  )

  return (
    <>
      {isInteropEnv ? (
        <InteropContextProvider>
          <Main />
        </InteropContextProvider>
      ) : (
        <Main />
      )}
    </>
  )
}

export default App
