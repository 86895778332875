import React, { useContext, useState } from 'react'
import { ModalWrapper, OverlayWrapper } from '../styles'
import { ButtonWrapper, ContentWrapper, ModalContentWrapper } from './styles'
import {
  WDLabelBlackSemiBold,
  WDLabelCardHeader,
} from '../../../components/ui/WDLabel'
import { WDButton, WDButtonDisabled } from '../../../components/ui/WDButton'
import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import { Textarea } from '../../../components/textArea/Textarea'
import {
  AccountRequestUpdate,
  RequestUpdateInterface,
} from '../../../services/Dashboard/DashboardApi'
import { AuthContext } from '../../../services/AuthContext'
import { RequestData } from '../NewDashboard'
import ToastContext from '../../../services/toast/ToastContext'

const ApprovalPopup = ({
  setCommentModal,
  isKYCPass,
  status,
  isPRDApproved,
  request,
  header,
  setData,
}: {
  setCommentModal: (arg0: boolean) => void
  isKYCPass: boolean
  status: string
  header: string
  isPRDApproved: boolean
  request: RequestData
  setData: (arg0: RequestData[]) => void
}) => {
  const { loggedUser } = useContext(AuthContext)
  const { setToast } = useContext(ToastContext)
  const [comments, setComments] = useState('')
  const getPayload = (status: string, request: RequestData) => {
    let payload = {} as RequestUpdateInterface
    if (status === 'Success') {
      payload = isKYCPass
        ? {
            action: 1,
            request: {
              requestId: request.requestId,
              kycCompletedBy: loggedUser,
              kycComment: comments,
            },
          }
        : {
            action: 2,
            request: {
              requestId: request.requestId,
              kycCompletedBy: loggedUser,
              kycComment: comments,
            },
          }
    } else if (status === 'KYC - Failed' && isKYCPass) {
      payload = {
        action: 1,
        request: {
          requestId: request.requestId,
          kycCompletedBy: loggedUser,
          kycComment: comments,
        },
      }
    } else if (status === 'KYC - Approved') {
      payload = isPRDApproved
        ? {
            action: 3,
            request: {
              requestId: request.requestId,
              approvedBy: loggedUser,
              comment: comments,
            },
          }
        : {
            action: 4,
            request: {
              requestId: request.requestId,
              approvedBy: loggedUser,
              comment: comments,
            },
          }
    } else if (status === 'PRD - Failed' && isPRDApproved) {
      payload = {
        action: 3,
        request: {
          requestId: request.requestId,
          approvedBy: loggedUser,
          comment: comments,
        },
      }
    }
    return payload
  }

  const updateRequest = (status: string, request: RequestData) => () => {
    AccountRequestUpdate(getPayload(status, request))
      .then(() => {
        setData([])

        setToast('success', 'Status updated')
      })
      .catch(() => {
        setToast('error', 'Failed to update status')
      })
    setCommentModal(false)
  }

  return (
    // JSX markup for your component goes here
    <OverlayWrapper>
      <ModalWrapper>
        <ModalContentWrapper>
          <ContentWrapper>
            <WDLabelCardHeader className="label_responsive">
              <Label>{header}</Label>
            </WDLabelCardHeader>
            <Textarea
              placeholder={'Add your comments...'}
              value={comments}
              onChange={(value) => setComments(value)}
              required
            />
            <ButtonWrapper>
              <WDButton>
                <Button
                  type={'button'}
                  onClick={updateRequest(status, request)}
                >
                  {'Submit'}
                </Button>
              </WDButton>
              <WDButtonDisabled>
                <Button type={'button'} onClick={() => setCommentModal(false)}>
                  {'Cancel'}
                </Button>
              </WDButtonDisabled>
            </ButtonWrapper>
          </ContentWrapper>
        </ModalContentWrapper>
      </ModalWrapper>
    </OverlayWrapper>
  )
}

export default ApprovalPopup
